<!--
 * @Author: 
 * @Date: 2020-11-07 09:09:45
 * @LastEditTime: 2021-03-17 14:23:31
-->
<template>
  <div class="component-list">
    <el-row style="min-height: 1100px">
      <el-col :span="4">
        <componentList
          @add-compt="addCompt"
          @change-tag="changeTag"
          :showComponents="!showThirdTable"
        ></componentList>
      </el-col>
      <template v-if="!showThirdTable">
        <el-col :span="9">
          <div class="preview-block">
            <preview
              :componentData="componentData"
              @selectComponent="selectComponent"
              @insertData="insertData"
              ref="preview"
            ></preview>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="action_menu">
            <componentCtrol v-if="canOperate" @operatetype="operateCpt"></componentCtrol>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="area-select" v-if="activeTab != 'thrid'">
            <el-form>
              <areaSelect v-model="areaId"></areaSelect>
            </el-form>
          </div>
          <previewEdit :editCptData="currentEditCptData" @page-info="getPageInfo"></previewEdit>
        </el-col>
      </template>
      <template v-else>
        <el-col :span="20">
          <el-card class="tinyContainer">
            <div>
              <el-col>
                <div class="area-select tinyTitle">
                  <el-form inline>
                    <areaSelect v-model="areaId"></areaSelect>
                    <el-form-item label="">
                      <el-button @click="showEidtHander">新增微页面</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </div>
            <el-table :data="tinyPageList" style="width: 100%" v-loading="tinyPageLoading">
              <el-table-column align="center" prop="id" label="页面ID" width="200"></el-table-column>
              <el-table-column align="center" prop="title" label="页面标题" width="180"></el-table-column>
              <!-- <el-table-column align="center" prop="pageContent" label="地区"></el-table-column> -->
              <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-button @click="getTinyPageDetail(scope.row.id)" type="text" size="small">编辑</el-button>
                  <el-button @click="showQrCodeHandler(scope.row.id)" type="text" size="small">小程序码</el-button>
                  <el-button @click="deleteTinyPage(scope.row.id)" type="text" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
      </template>
    </el-row>
    <!-- 小程序码 -->
    <el-dialog :close-on-click-modal="false" :visible.sync="showQrcode" title="小程序码" width="520px">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <img :src="wxQrcode" alt style="width: 230px" />
        <span style="margin-top: 20px;">页面路径: {{ `pages/tinyPage/index?id=${currentQrId}` }}</span>
      </div>
    </el-dialog>
    <!-- 删除组件 -->
    <bm-deleteItem
      url="boom-center-admin-service/sysAdmin/decorationMicroPage/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
import componentList from './componentList'
import componentCtrol from './componentControl'
import preview from './preview'
import previewEdit from './previewEdit'
import areaSelect from '@/component/common/areaSelect'
import { log } from '@/util/log'

export default {
  name: 'component-list',
  components: { componentList, preview, componentCtrol, previewEdit, areaSelect },

  data() {
    return {
      footBarModel: {
        componentName: 'footBar',
        selectStatus: true,
        data: [
          {
            hideStatus: 0
          }
        ]
      },
      pageTitleModel: {
        componentName: 'pageTitle',
        selectStatus: true,
        data: []
      },
      discoveryModel: {
        componentName: 'discover',
        selectStatus: true,
        data: [
          {
            hideStatus: 0
          }
        ]
      },
      tinyComponentModel: {
        componentType: 'TEXT',
        componentData: {},
        componentName: '',
        hide: true
      },
      tinyPageModel: {
        abstractContent: '',
        image: '',
        pageContent: '',
        title: ''
      },
      componentData: [],
      componentDataModel: {},
      currentActiveTag: 'first',
      currentEditCptData: {},
      canOperate: true,
      selectComponentIndex: 1, //当前编辑组件索引
      areaId: -1,
      showThirdTable: false,
      activeTab: '',
      tinyPageList: [],
      pageInfo: {},
      showQrcode: false,
      wxQrcode: '',
      timestamp: 0,
      tinyPageLoading: true,
      currentQrId: ''
    }
  },
  created() {
    if (this.currentAreaId == -1) {
      this.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.areaId = this.currentAreaId
    }
    this.getModule()
  },
  provide() {
    return {
      addidtocomponent: this.addidtocomponent,
      pageInfo: this.tinyPageModel,
      savaTinyPage: this.savaTinyPage
    }
  },
  methods: {
    // 新增微页面
    getPageInfo(val) {
      for (let item in this.tinyPageModel) {
        this.tinyPageModel[item] = val[item]
      }
      this.tinyPageModel.pageContent = JSON.stringify(this.componentData)
      if (!this.currentTinyPageId) {
        this.addTiny(this.tinyPageModel)
      } else {
        this.tinyPageModel.id = this.currentTinyPageId
        this.updateTiny(this.tinyPageModel,true)
      }
    },
    savaTinyPage() {
      for (let c of this.componentData) {
        if (c.componentName.indexOf('productListComponent') > -1 || c.componentName.indexOf('imageUrl') > -1 || c.componentName.indexOf('textContent') > -1) {

        } else {
          c.data = []
        }
      }
      this.tinyPageModel.pageContent = JSON.stringify(this.componentData)
      if (!this.currentTinyPageId) {
        this.addTiny(this.tinyPageModel)
      } else {
        this.tinyPageModel.id = this.currentTinyPageId
        this.updateTiny(this.tinyPageModel)
      }
      setTimeout(() => {
        this.getTinyPageDetailData()
      }, 500)
    },
    // 新增微页面
    showEidtHander() {
      this.showThirdTable = false
      this.componentData.push(this.pageTitleModel)
      this.selectComponent(0)
    },
    //给瀑布流 或 魔方组件绑定活动id ,限时秒杀绑定主图
    addidtocomponent(params) {
      // { id, activeStatus, productid,mainImage}
      for (let i in params) {
        this.componentData[this.selectComponentIndex][i] = params[i]
      }
      if (this.activeTab == 'thrid') {
        this.tinyPageModel.pageContent = JSON.stringify(this.componentData)
        this.updateTiny(this.tinyPageModel)
      } else {
        this.postModule()
      }
    },

    // 处理组件列表切换的场景
    changeTag(v) {
      this.selectComponentIndex = 1
      this.activeTab = v
      if (v == 'first') {
        this.showThirdTable = false
        this.getModule()
      } else if (v == 'second') {
        this.showThirdTable = false
        this.getDiscoveryDetail()
      } else {
        this.showThirdTable = true
        this.componentData = []
        this.currentEditCptData = {}
        this.currentTinyPageId = ''
        this.getTinyPageList()
      }
    },
    //编辑状态组件内添加数据
    addClassify() { },
    addCompt(name) {
      if (this.activeTab == 'thrid' && (name == 'textContent' || name == 'imageUrl' || name == 'productListComponent' || name.indexOf('cube_') > -1)) {
        switch (name) {
          case 'textContent':
            this.tinyComponentModel.componentType = 'TEXT'
            this.tinyComponentModel.componentData = {
              textContent: ''
            }
            break
          case 'imageUrl':
            this.tinyComponentModel.componentType = 'IMAGE'
            this.tinyComponentModel.componentData = {
              imageUrl: ''
            }
            break
          case 'productListComponent':
            this.tinyComponentModel.componentType = 'PRODUCT'
            this.tinyComponentModel.componentData = {
              sortType: 0,
              productList: '',
              isFilterSoldOut: false
            }
            break
          default:
            break
        }
        this.addTinyPageModule(this.tinyComponentModel,true)
      }
      this.insertData(name, this.componentData.length)
    },
    /**
     *  组件搭配组合
     */
    postModule() {
      let cptDataModel = [...this.componentData]
      for (let c of cptDataModel) {
        if (c.componentName.indexOf('productListComponent') > -1 || c.componentName.indexOf('imageUrl') > -1 || c.componentName.indexOf('textContent') > -1) {

        } else {
          c.data = []
        }
      }
      this.componentDataModel.moduleData = JSON.stringify(cptDataModel)
      this.$http
        .post('boom-center-admin-service/sysAdmin/decorationModule', this.componentDataModel, {
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.getModule()
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    getModule() {
      this.$http
        .get('boom-center-admin-service/sysAdmin/decorationModule', {
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.componentDataModel = res
          let modules = res.moduleData ? JSON.parse(res.moduleData) : []
          if (!modules.length) {
            this.componentData = []
            if (this.activeTab == 'third') {
              this.componentData.push(this.pageTitleModel)
            } else {
              this.componentData.push(this.footBarModel)
            }
          } else {
            this.componentData = [...modules]
          }
          this.getCompontentDetail().then(res => {
            let errorItem = res.find(c => c.status === 'rejected')
            // 有报错的提示
            if (errorItem) {
              this.$message.error(errorItem.reason)
            }
            let [
              classifyData,
              carouselData,
              footBarData,
              waterfallData,
              areaAndClassifyData,
              cubeData,
              homePageDialogData,
              seckillData
            ] = res

            this.componentData.map(c => {
              if (c.componentName == 'classify') {
                c.data = [...classifyData.value]
              } else if (c.componentName == 'carousel') {
                c.data = [...carouselData.value]
              } else if (c.componentName == 'footBar') {
                c.data = [...footBarData.value]
              } else if (c.componentName.indexOf('waterfall') > -1) {
                for (let cc of waterfallData.value) {
                  if (c.id == cc.id) c.data = [cc]
                }
              } else if (c.componentName == 'areaAndClassify') {
                c.data = [...areaAndClassifyData.value]
              } else if (c.componentName.indexOf('cube_') > -1) {
                for (let cc of cubeData.value) {
                  if (c.id == cc.id) c.data = [cc]
                }
              } else if (c.componentName == 'homePageDialog') {
                c.data = [...homePageDialogData.value]
              } else if (c.componentName == 'seckill') {
                c.data = [...seckillData.value]
              }
            }) //getPopupList
            this.selectComponent(this.selectComponentIndex)
          })

        })
        .catch(err => {
          this.$log.error(err)
        })
    },
    /***
     * 获取组件的明细数据
     */
    getModelData(url) {
      return this.$http.get(url, {
        headers: {
          ['Bm-Area-Id']: this.areaId
        }
      })
    },
    getCompontentDetail() {
      return Promise.allSettled([
        // 金刚区列表
        this.getModelData('boom-center-admin-service/sysAdmin/zone/list'),
        // 轮播图列表
        this.getModelData('boom-center-admin-service/sysAdmin/banner/list'),
        // 底部菜单
        this.getModelData('boom-center-admin-service/sysAdmin/decorationBottomBar'),
        // 瀑布流
        this.getModelData('boom-center-admin-service/sysAdmin/waterfall/list'),
        // 商圈/分类
        this.getModelData('boom-center-admin-service/sysAdmin/decorationHorizontalNavigation'),
        // 魔方组件数据
        this.getModelData('boom-center-admin-service/sysAdmin/decorationMagicCube/list'),
        // 弹窗数据
        this.getModelData('boom-center-admin-service/sysAdmin/popUps/list'),
        // 秒杀
        this.getModelData('boom-center-product-service/sysAdmin/limitedTimeSaleActivity/started')
      ])
    },
    // 发现板块 数据独立获取
    getDiscoveryDetail() {
      this.componentData = [this.footBarModel, this.discoveryModel]
      let getDiscoveryList = this.getModelData('boom-center-admin-service/sysAdmin/decorationDiscovery')
      let getBottomBarList = this.getModelData('boom-center-admin-service/sysAdmin/decorationBottomBar')
      Promise.all([getBottomBarList, getDiscoveryList]).then(res => {
        this.componentData[0].data = [...res[0]]
        this.componentData[1].data = [...res[1]]
        this.selectComponent(this.selectComponentIndex)
      })
    },
    insertData(componentName, index) {
      //组件只能有一个只能添加一个
      let modeLimitOnlyOne = [
        'homePageDialog',
        'search',
        'search2',
        'carousel',
        'searchAndCarousel',
        'areaAndClassify',
        'classify',
        'seckill'
      ]
      let insertDataItem = name => {
        return {
          componentName: name,
          selectStatus: false,
          activeStatus: true,
          data: [{ hideStatus: 0 }]
        }
      }
      if (modeLimitOnlyOne.includes(componentName)) {
        for (let v of this.componentData) {
          if (
            componentName == v.componentName ||
            ((componentName == 'search2' || componentName == 'searchAndCarousel') &&
              (v.componentName == 'search' || v.componentName == 'carousel'))
          ) {
            this.$message.info('亲，此类组件已经有了哦。')
            return
          }
        }
      }
      this.selectComponentIndex = index
      if (componentName == 'searchAndCarousel' || componentName == 'search2') {
        ;['carousel', 'search'].forEach(v => {
          this.componentData.splice(index, 0, {
            ...insertDataItem(v)
          })
          // hideAreaListSelect值为了隐藏search 组件中的区域选择功能，
          if (componentName == 'search2' && v == 'search') {
            this.componentData[index]['hideAreaListSelect'] = true
          }
        })
      } else if (componentName.indexOf('cube_') > -1) {
        this.componentData.splice(index, 0, {
          ...insertDataItem(componentName),
          id: ''
        })
      } else if (componentName.indexOf('waterfall') > -1) {
        this.$log('插入')
        this.componentData.splice(index, 0, {
          ...insertDataItem(componentName),
          productGroupId: '',
          id: '',
          textOrImage: 'image'
        })
      } else if (componentName.indexOf('seckill') > -1) {
        this.$log('插入')
        this.componentData.splice(index, 0, {
          ...insertDataItem(componentName),
          mainImage: ''
        })
      } else if (componentName.indexOf('textContent') > -1) {
        this.componentData.splice(index, 0, {
          ...insertDataItem('textContent'),
          id: ''
        })
      } else if (componentName.indexOf('imageUrl') > -1) {
        this.componentData.splice(index, 0, {
          ...insertDataItem('imageUrl'),
          id: ''
        })
      } else if (componentName.indexOf('productListComponent') > -1) {
        this.componentData.splice(index, 0, {
          ...insertDataItem('productListComponent'),
          id: ''
        })
      } else {
        this.componentData.splice(index, 0, {
          ...insertDataItem(componentName)
        })
      }
      if (this.activeTab == 'thrid') {
      } else {
        this.postModule()
      }
    },

    // 设定选定组件状态
    selectComponent(index) {
      if (this.componentData.length == 1) index = 0
      if (arguments.length) {
        this.selectComponentIndex = index
      }
      this.componentData.map((v, i) => {
        if (v.selectStatus && this.selectComponentIndex != i) {
          v.selectStatus = false
        } else if (this.selectComponentIndex == i) {
          v.selectStatus = true
        }
      })
      this.currentEditCptData = { ...this.componentData[this.selectComponentIndex] }
      if (this.currentEditCptData.componentName == 'footBar') {
        this.canOperate = false
      } else if (this.currentEditCptData.componentName == 'pageTitle') {
        this.canOperate = false
      } else {
        this.canOperate = true
      }
    },
    operateCpt(type) {
      if (type == 1) {
        if (this.selectComponentIndex > 1) {
          let tem = this.componentData[this.selectComponentIndex]
          this.componentData.splice(this.selectComponentIndex, 1)
          this.componentData.splice(this.selectComponentIndex - 1, 0, tem)
          --this.selectComponentIndex
          if (this.activeTab == 'thrid') {
            this.savaTinyPage()
          } else {
            this.postModule()
          }
        } else {
          this.$message.info('已处于最前端')
        }
      } else if (type == -1) {
        let len = this.componentData.length || 0
        if (this.selectComponentIndex < len - 1) {
          let tem = this.componentData[this.selectComponentIndex]
          this.componentData.splice(this.selectComponentIndex, 1)
          this.componentData.splice(this.selectComponentIndex + 1, 0, tem)
          this.selectComponentIndex++
          if (this.activeTab == 'thrid') {
            this.savaTinyPage()
          } else {
            this.postModule()
          }
        } else {
          this.$message.info('已处于最末端')
        }
      } else if (type == 100) {
        if (this.selectComponentIndex > 1) {
          let tem = this.componentData[this.selectComponentIndex]
          this.componentData.splice(this.selectComponentIndex, 1)
          this.componentData.unshift(tem)
          this.selectComponentIndex--
          if (this.activeTab == 'thrid') {
            this.savaTinyPage()
          } else {
            this.postModule()
          }
        } else {
          this.$message.info('已置顶')
        }
      } else if (type == -100) {
        let len = this.componentData.length || 0
        if (this.selectComponentIndex < len - 1) {
          let tem = this.componentData[this.selectComponentIndex]
          this.componentData.splice(this.selectComponentIndex, 1)
          this.componentData.splice(len - 1, 0, tem)
          this.selectComponentIndex = len - 1
          if (this.activeTab == 'thrid') {
            this.savaTinyPage()
          } else {
            this.postModule()
          }
        } else {
          this.$message.info('已置底')
        }
      } else if (type == 0) {
        this.componentData.splice(this.selectComponentIndex, 1)
        if (this.selectComponentIndex == this.componentData.length) {
          this.selectComponentIndex--
        }
        if (this.activeTab == 'thrid') {
          this.savaTinyPage()
        } else {
          this.postModule()
        }
      } else {
        this.$message.info('请先选定需要编辑的组件')
      }
    },
    addTinyPageModule(model) {
      this.$http.post('boom-center-admin-service/sysAdmin/micro/page', { ...model }, {
        headers: {
          ['Bm-Area-Id']: this.areaId
        }
      }).then(res => {
        this.componentData[this.componentData.length - 1].data = [res]
        this.savaTinyPage()
      })
    },
    // 获取微页面列表 
    getTinyPageList() {
      this.getModelData('boom-center-admin-service/sysAdmin/decorationMicroPage').then(res => {
        this.tinyPageList = res.list
        this.tinyPageLoading = false
      })
    },
    // 新增微页面
    addTiny(data) {
      this.$http.post('boom-center-admin-service/sysAdmin/decorationMicroPage', { ...data }, {
        headers: {
          ['Bm-Area-Id']: this.areaId
        }
      }).then(res => {
        this.currentTinyPageId = res.id
        this.$message.success("新增成功")
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    // 更新微页面
    updateTiny(data,init = false) {
      this.$http.put('boom-center-admin-service/sysAdmin/decorationMicroPage', { ...data }, {
        headers: {
          ['Bm-Area-Id']: this.areaId
        }
      }).then(res => {
        if(init){
          this.$message.success("修改成功")
        }
      }).catch(e => {
        this.$message.error(e.msg)
      })
    },
    // 获取微页面详情
    getTinyPageDetail(id) {
      this.currentTinyPageId = id
      this.getTinyPageDetailData(true)
    },
    getTinyPageDetailData(init = false) {
      let pageData = new Promise(resolve => resolve(this.$http.get(`boom-center-admin-service/sysAdmin/decorationMicroPage/${this.currentTinyPageId}`, { headers: { ['Bm-Area-Id']: this.areaId } })))
      let componentData = new Promise(resolve => resolve(this.getModelData('boom-center-admin-service/sysAdmin/decorationMagicCube/list')))
      Promise.all([pageData, componentData]).then(res => {
        const [pageDetail, componentList] = res
        this.tinyPageModel = pageDetail
        this.componentData = JSON.parse(pageDetail.pageContent)
        this.componentData.map(c => {
          if (c.componentName.indexOf('cube_') > -1) {
            for (let cc of componentList) {
              if (c.id == cc.id) c.data = [cc]
            }
          }
          return c
        })
        this.componentData[0].data = [this.tinyPageModel]
        this.showThirdTable = false
        if (init) {
          this.selectComponent(0)
        } else if(this.selectComponentIndex){
          this.selectComponent(this.selectComponentIndex)
        }else{
          this.selectComponent(this.componentData.length - 1)
        }
      })
    },
    // 删除微页面
    deleteTinyPage(id) {
      this.timestamp = parseInt(new Date().getTime())
      sessionStorage.setItem('deleteItemId', id)
    },
    successDelete() {
      this.$message.success('删除成功')
      const that = this
      setTimeout(() => {
        that.getTinyPageList()
      }, 500)
    },
    // 小程序二维码弹窗
    showQrCodeHandler(id) {
      this.currentQrId = id
      //生成页面小程序码
      this.$http
        .post('boom-center-user-service/sysAdmin/wechat/create_mini_app_code_unlimit', {
          page: 'pages/tinyPage/index',
          scene: `id=${id}`,
          width: 800
        })
        .then(res => {
          this.wxQrcode = res.codeUrl
          this.showQrcode = true
        })
        .catch(e => {
          this.$message.error('生成二维码失败')
        })
    }
  },
  watch: {
    areaId: {
      handler(n) {
        this.$store.commit('malls/setCurrentEditAreaId', n)
        localStorage.setItem("currentAreaId",n)
        this.selectComponentIndex = 1
        if (this.activeTab == 'thrid') {
          this.getTinyPageList()
        } else {
          this.getModule()
        }
      },
      immediate: true
    }
  },
  computed: {
    currentAreaId() {
      //当前全局区域id
      let currentAreaId = this.$store.getters['user/currentAreaId']
      if (currentAreaId != -1) {
        this.$store.commit('malls/setCurrentEditAreaId', currentAreaId)
      }
      return currentAreaId
    }
  }
}
</script>

<style lang="less" scoped>
.component-list {
  height: 100%;
  padding: 20px 0;
  .preview-block {
    text-align: center;
  }
  .action_menu {
    min-width: 136px;
    display: inline-block;
    position: relative;
    top: 200px;
    right: 10%;
    transition: all 0.2s linear;
  }
  .operate {
  }
  .area-select {
    background-color: @color-background;
    padding: 10px;
    margin-bottom: 10px;
  }
  .tinyContainer {
    margin: 0 20px;
  }
  .tinyTitle {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
